import React from 'react';
import { Modal } from '@andes/modal';

import classNames from 'classnames';
// Andes Types
import type { DropdownFormProps } from '@andes/dropdown';
import type { AutocompleteProps } from '@andes/autocomplete';
import type { ButtonProps } from '@andes/button';
import type { RadioListProps } from '@andes/radio-list';
import type { ModalProps } from '@andes/modal';

// Andes Components
import { DropdownForm, DropdownItem } from '@andes/dropdown';
import { Autocomplete } from '@andes/autocomplete';
import { TextField } from '@andes/textfield';
import { Button } from '@andes/button';
import { RadioList, RadioListItem } from '@andes/radio-list';

import { DeviceType } from '../../constants/commons';

export type State = {
  id: string;
  name: string;
};

export type City = {
  id: string;
  name: string;
};

export type SelectedCity = {
  name: string;
  disabled: boolean;
};

export type Error = {
  city: {
    error: boolean;
    message: string;
  };
};

export type ZipCodesInformation = {
  zipCode: string;
  city: string;
  street: string;
  state: string;
  neighborhood: string;
};

export type ZipCodeModalProps = {
  title: string;
  statePlaceholder: string;
  stateLabel: string;
  statesList: Array<State>;
  citiesList: Array<City>;
  handleStateChange: DropdownFormProps['onChange'];
  handleCityChange: AutocompleteProps['onSelect'];
  handleSuggestedZipCodes: () => void;
  handleAddressChange: ButtonProps['onClick'];
  handleZipCodeChange: RadioListProps['onChange'];
  handleSubmit: ButtonProps['onClick'];
  selectedState: State;
  selectedCity: SelectedCity;
  cityPlaceholder: string;
  cityLabel: string;
  findZipCodeButtonLabel: string;
  zipCodesInformation?: Array<ZipCodesInformation>;
  backButtonText: string;
  continueButtonText: string;
  cityErrorModifier: string;
  zipCodeWording: string;
  isOpen?: boolean;
  deviceType?: DeviceType.DESKTOP | DeviceType.MOBILE;
  className?: string;
  maxWidth?: number;
  onClose?: ModalProps['onClose'];
};

const ZipCodeModal = ({
  title,
  statePlaceholder,
  stateLabel,
  statesList = [],
  citiesList = [],
  handleStateChange,
  handleCityChange,
  handleSuggestedZipCodes,
  handleAddressChange,
  handleZipCodeChange,
  handleSubmit,
  selectedState = { id: '', name: '' },
  selectedCity = { name: '', disabled: true },
  cityPlaceholder,
  cityLabel,
  findZipCodeButtonLabel,
  zipCodesInformation,
  backButtonText,
  continueButtonText,
  cityErrorModifier,
  isOpen = false,
  zipCodeWording,
  deviceType = DeviceType.DESKTOP,
  onClose,
}: ZipCodeModalProps) => {
  const [errors, setErrors] = React.useState<Error>({
    city: { error: false, message: '' },
  });

  const clearErrors = () => {
    setErrors({
      city: { error: false, message: '' },
    });
  };
  const handleCitiesError = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const normalizeString = (str: string) =>
      str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

    const existSomeCity = citiesList.some((city) =>
      normalizeString(city.name).includes(normalizeString(event.target.value)),
    );

    setErrors((prevErrors) => ({
      ...prevErrors,
      city: {
        error: !existSomeCity,
        message: existSomeCity ? '' : cityErrorModifier,
      },
    }));
  };

  const handleFindZipCodeClick = () => {
    const hasErrors = Object.values(errors).some((err) => err.error);

    if (!hasErrors && selectedState.id !== '' && selectedCity.name !== '') {
      handleSuggestedZipCodes();
    }
  };

  React.useEffect(() => {
    clearErrors();
  }, [citiesList]);

  const backButton = () => (
    <Button
      hierarchy="quiet"
      size="large"
      onClick={handleAddressChange}
      fullWidth={deviceType === DeviceType.MOBILE}
    >
      {backButtonText}
    </Button>
  );

  const continueButton = () => (
    <Button
      hierarchy="loud"
      size="large"
      onClick={handleSubmit}
      fullWidth={deviceType === DeviceType.MOBILE}
    >
      {continueButtonText}
    </Button>
  );

  const NEED_TO_OVERRIDE_MODAL_SCROLL =
    zipCodesInformation &&
    zipCodesInformation.length === 0 &&
    deviceType === DeviceType.DESKTOP;

  return (
    <React.Fragment>
      <Modal
        className={classNames({
          'zip-code-modal__pure-andes-styles': !NEED_TO_OVERRIDE_MODAL_SCROLL,
          'zip-code-modal__modal-scroll-override':
            NEED_TO_OVERRIDE_MODAL_SCROLL,
        })}
        title={title}
        closable={true}
        open={isOpen}
        onClose={onClose}
        type={deviceType === DeviceType.MOBILE ? 'full' : 'small'}
        actions={
          zipCodesInformation && zipCodesInformation.length > 0
            ? {
                fixed: true,
                primary:
                  deviceType === DeviceType.MOBILE
                    ? continueButton()
                    : backButton(),
                secondary:
                  deviceType === DeviceType.MOBILE
                    ? backButton()
                    : continueButton(),
              }
            : undefined
        }
      >
        {zipCodesInformation && zipCodesInformation.length === 0 && (
          <div className="zip-code-modal__finder-selector">
            <DropdownForm
              id="dropdown-story-id"
              label={stateLabel}
              onChange={handleStateChange}
              placeholder={statePlaceholder}
              srLabel={stateLabel}
              menuAlignment="bottom"
              menuMaxHeight={300}
            >
              {statesList.map((state) => (
                <DropdownItem
                  key={state.id}
                  title={state.name}
                  value={`${state.id}|${state.name}`}
                />
              ))}
            </DropdownForm>
            <div className="zip-code-modal__finder-selector__autocomplete">
              <Autocomplete
                suggestions={citiesList}
                onSelect={handleCityChange}
              >
                <TextField
                  onChange={handleCitiesError}
                  label={cityLabel}
                  disabled={selectedCity.disabled}
                  placeholder={cityPlaceholder}
                  helper={errors.city.error && errors.city.message}
                  modifier={errors.city.error ? 'error' : undefined}
                />
              </Autocomplete>
            </div>
            <div className="zip-code-modal__cta-button">
              <Button
                hierarchy="loud"
                size="large"
                onClick={handleFindZipCodeClick}
                fullWidth={deviceType === DeviceType.MOBILE}
              >
                {findZipCodeButtonLabel}
              </Button>
            </div>
          </div>
        )}

        {zipCodesInformation && zipCodesInformation.length > 0 && (
          <div className="zip-code-modal__zip-suggestions">
            <RadioList
              defaultValue={'0'}
              withDividers
              type="default"
              onChange={handleZipCodeChange}
            >
              {zipCodesInformation.map(
                ({ zipCode, street, neighborhood }, index) => (
                  <RadioListItem
                    size="medium"
                    paddingSize="0"
                    key={zipCode}
                    value={index}
                    title={`${
                      neighborhood ? `${neighborhood}, ` : ''
                    } ${zipCodeWording} ${zipCode}`}
                    description={
                      <>
                        {street} <br />
                        {selectedState.name}
                      </>
                    }
                  />
                ),
              )}
            </RadioList>
          </div>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default React.memo(ZipCodeModal);
