const PropTypes = require('prop-types');

const { Accordion } = require('@andes/accordion');
const { Typography } = require('@andes/typography');
const { Tooltip } = require('@andes/tooltip');
const Info = require('@andes/icons/Info16');
const { ANDES_STYLES_THEME, COMMONS, COLORS } = require('../../../../constants');

const {
  COLORS: { PRIMARY, SECONDARY },
  SIZES: { M, S },
  WEIGHT: { REGULAR, SEMI_BOLD },
} = ANDES_STYLES_THEME;

const { ANDES_PLACEMENT: { TOP }, TOOLTIP_TYPE: { DARK } } = COMMONS;

const Markdown = require('../../../components/Markdown');


const PCJSummary = ({ pcj, items, translations, totalSummary }) => {

  const { acrescimo, juros, price } = pcj;

  const { name, price: priceTotal } = items?.[0] || {};
  const total = priceTotal?.text_value || '';

  return (
    <>
      <div className="pcj-summary">
        <Accordion
          title={
            <div className="flex-between-center">
              <Typography
                size={M}
                weight={REGULAR}>{name}</Typography>
              <Typography
                size={M}
                weight={REGULAR}>{total}</Typography>
            </div>
          }
        >
          <>
            <div className="flex-between-center">
              <Typography
                size={S} color={SECONDARY}>
                {price?.name}
              </Typography>
              <Typography
                size={S}
                color={SECONDARY}>
                {price?.price?.text_value}
              </Typography>
            </div>
            <div className="flex-between-center">
              <div className="flex-between-center">
                <Typography size="s" color="secondary">{acrescimo?.name}</Typography>
                <Tooltip
                  side={TOP}
                  type={DARK}
                  className="pcj-tooltip"
                  content={translations.THERE_IS_A_PRICE_DIFFERENCE_DEPENDING_ON_THE_NUMBER_OF_INSTALLMENTS_YOU_CHOOSE}
                >
                  <Info />
                </Tooltip>
              </div>
              <Typography
                size={S}
                color={SECONDARY}>
                {acrescimo?.price?.text_value}
              </Typography>
            </div>
          </>
        </Accordion>
        {juros?.name &&
          <div className="flex-between-center pcj-summary__juros">
            <Typography
              size={M}
              weight={REGULAR}
              color={PRIMARY}
            >
              <Markdown text={juros.name} />
            </Typography>
            <Typography
              size={M}
              weight={REGULAR}
              color={PRIMARY}>
              {juros?.price?.text_value}
            </Typography>
          </div>
        }
      </div>
    </>
  );
};

PCJSummary.propTypes = {
  pcj: PropTypes.object,
  items: PropTypes.array,
  translations: PropTypes.object,
  totalSummary: PropTypes.object,
};

module.exports = PCJSummary;
