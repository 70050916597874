const React = require("react");
const PropTypes = require("prop-types");
const {Title, Typography} = require('@andes/typography');
const CardList = require('@cow/core-components/CardList');

const ShippingType = require("../icons/ShippingType");
const {isMobile} = require("../../utils/webview");
const Pin = require("../icons/Pin");
const { ANDES_STYLES_THEME } = require('../../../constants');

const { SIZES: {XS, M }, COLORS: { LINK, GREEN}, WEIGHT: { SEMI_BOLD }} = ANDES_STYLES_THEME;

const ShippingCard = (
  {
    shipping,
    changeShippingAddress,
    changeShippingOption,
    deviceType,
    translations
  }) => {

  if (!shipping) {
    return;
  }

  const items = [];

  const callToAction = (
    <Typography
      size={M}
      weight={SEMI_BOLD}
      component="span"
      color={LINK}
    >
      {translations.MODIFY}
    </Typography>
  );

  items.push({
    title: shipping?.delivery?.address?.street,
    description: shipping?.delivery?.address?.details,
    extraInfo: shipping?.delivery?.address?.contact,
    icon: <Pin/>,
    onClick: changeShippingAddress,
    callToAction,
    isMobile: isMobile(deviceType)
  });

  items.push({
    title: shipping?.delivery?.arrival,
    description: shipping?.delivery?.price === "" ? translations?.FREE_SHIPPING : shipping?.delivery?.price,
    descriptionColor: shipping?.delivery?.price === "" ? GREEN : '',
    icon: <ShippingType/>,
    onClick: changeShippingOption,
    callToAction,
    isMobile: isMobile(deviceType)
  });

  return (
    <div className="shipping-card">
      <Title component="h2" size={XS}>{translations.SHIPPING_DATA}</Title>
      <CardList items={items}/>
    </div>
  );
};

ShippingCard.propTypes = {
  shipping: PropTypes.shape({
    is_pickup: PropTypes.bool,
    delivery: PropTypes.shape({
      address: PropTypes.shape({
        street: PropTypes.string,
        details: PropTypes.string,
        contact: PropTypes.string,
      }),
      arrival: PropTypes.string,
      price: PropTypes.string,
    })
  }),
  translations: PropTypes.object.isRequired,
}

module.exports = ShippingCard;
