const React = require('react');
const PropTypes = require('prop-types');
const connectToReduxAndInjectI18n = require('../../utils/connectToRedux');

const { bindActionCreators } = require('redux');
const buttonActions = require('../../spa/actions/button');

const { Button: AndesButton } = require('@andes/button');
const ArrowLeft = require('@andes/icons/ArrowLeft16');
const { useExternalLink } = require('../../hooks/useExternalLink');
const { PAGE_NAME } = require('../../../constants/app');

const BUTTON_SIZE = {
  SMALL: 'small',
  LARGE: 'large',
  MEDIUM: 'medium',
};

const ButtonBackURLs = ({ buttonAction, buttonRequest, isWebview, id, label, url, step }) => {
  const { handleClick, sanitizedUrl } = useExternalLink({ buttonAction, buttonRequest, isWebview, url });

  const size = step.includes(PAGE_NAME.CONGRATS) ? BUTTON_SIZE.LARGE : BUTTON_SIZE.MEDIUM;

  return (
    <AndesButton
      className="cow-button-back-to-site"
      hierarchy="transparent"
      href={sanitizedUrl}
      id={id}
      onClick={handleClick}
      size={size}
    >
      <ArrowLeft />
      {label}
    </AndesButton>
  );
};

ButtonBackURLs.propTypes = {
  buttonAction: PropTypes.shape({
    BUTTON_REQUEST_TRIGGERED: () => {},
  }),
  buttonRequest: PropTypes.bool,
  isWebview: PropTypes.bool,

  id: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
};

ButtonBackURLs.defaultProps = {
  buttonAction: {},
  buttonRequest: false,
  isWebview: false,
  id: '',
  label: '',
  url: '',
};

const mapDispatchToProps = (dispatch) => ({
  buttonAction: bindActionCreators(buttonActions, dispatch),
});

const mapStateToProps = ({ page, button, configurations }) => ({
  step: page.flow.step,
  buttonRequest: button.buttonRequest,
  isWebview: configurations.isWebview,
});

module.exports = connectToReduxAndInjectI18n(
  ButtonBackURLs,
  mapStateToProps,
  mapDispatchToProps,
);
