const React = require('react');
const PropTypes = require('prop-types');

// Store
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');
// i18n
const injectI18n = require('nordic/i18n/injectI18n');
// Card Form Brick
const { CardFormProvider } = require('internal-checkout-bricks/lib/cardForm/client');
const { InternalProviderBricks } = require('internal-checkout-bricks');
// Andes Components
const { Title } = require('@andes/typography');
// Cow Components
const Navbar = require('@cow/core-components/components/NavbarV2');
const PaymentSummary = require('@cow/core-components/components/PaymentSummary');
const Brand = require('@cow/core-components/components/Brand');
const GenericModal = require('@cow/core-components/components/GenericModal');

// Internal Components
const Page = require('../../../../components/Page');
const CardFormWrapper = require('../components/CardFormWrapper');
const CallToActionButtons = require('../components/CallToActionButtons');
const CardFormErrorBoundary = require('../components/CardFormErrorBoundary');
// Constants
const { ENVIROMENT, SITE_ID, THEME, CHECKOUT_TYPE } = require('../../../../../constants/commons');
// Actions
const STEP_ACTIONS = require('../../../../spa/actions/step');
const SNACKBAR_ACTIONS = require('../../../../spa/actions/snackbar');
const CARD_FORM_ACTIONS = require('../../../../spa/actions/cardFormBricks');

// Hooks
const { useActions } = require('../hooks/useActions');
// Utils
const { newInterfaceGenericPropsTypes, genericDefaultValues } = require('../../../../utils/propTypes');
const { useLogOut } = require('../../../../hooks/useLogOut');
const { getCompanyTheme, getBrandNameCompany } = require('../../../review/templates/utils/configurations');
const { isMobile } = require('../../../../utils/webview');
const { handleSummaryItemProps, PAYMENT_SUMMARY_ALIGNMENT } = require('../../../../utils/summary');
const translate = require('../../../../translation');
const { logErrorFromClient } = require('../../../../utils/logTags');

const CardFormPage = (props) => {
  // Ref
  const cardFormWrapperRef = React.useRef();
  const tooltipContainer = React.createRef();

  // States
  const [showDiscountModal, setShowDiscountModal] = React.useState(false);

  const {
    currentStep,
    flow,
    history,
    stepActions,
    templateCase,
    siteId,
    deviceType,
    browserName,
    snackbarActions,
    cardFormActions,
    internalBricksData,
    configs: {
      publicKey,
      siteSecurity: { session_id: meliSessionId },
    },
    i18n,
    step_model,
    isManagingError,
    platform,
    rebrandingMP,
  } = props;

  const translations = translate(i18n);

  const {
    brand,
    discount,
    payer,
    step_title,
    summary,
    card_form_configuration: { is_zero_dollar_enabled },
    can_return_to_options,
    navigation,
  } = step_model;

  const { changePaymentMethod, navigateToErrorPage, navigateToPendingBackUrl } = useActions({
    flow,
    history,
    stepActions,
  });

  const { logout } = useLogOut({
    flow,
    siteId,
    browserName,
    history,
    stepActions,
    snackbarActions,
    translations,
    platform,
    configurations: props.configs
  });

  // States
  const [isContinueBtnDisabled, setIsContinueBtnDisabled] = React.useState(false);

  const handleTokenizeCardClick = async () => {
    setIsContinueBtnDisabled(true);
    try {
      await (is_zero_dollar_enabled
        ? cardFormWrapperRef.current?.handleGenerateZeroDollarToken()
        : cardFormWrapperRef.current?.handleGenerateToken());
    } catch (error) {
      navigateToErrorPage();
    }
    setIsContinueBtnDisabled(false);
  };

  // TODO this is used in review. Need to be in shared place
  const NavbarMenuOptions = {
    title: translations.YOU_ENTERED_AS,
    changeUserLabel: translations.CHANGE_ACCOUNT,
    logOutUserLabel: translations.LOG_OUT,
  };

  // If the cardFormErrorBoundary is managing an error, do not render the New Card Form and redirect to exception page
  if (isManagingError) {
    return null;
  }

  const theme = getCompanyTheme(props.configs?.internalConfigurations);
  const shouldReturnToOptions = !can_return_to_options && navigation?.back_urls?.pending?.length > 0;

  // ====== Methods =====
  function backButtonAction() {
    if (shouldReturnToOptions) {
      // We are using the "pending" property of the back_urls object and not the others ("success" and "failure")
      // because in this instance the user is not completing the checkout, but just returning to the predefined pending URL
      // Which is usually the site of the integrator.
      return navigateToPendingBackUrl(navigation.back_urls.pending);
    }

    return changePaymentMethod();
  }

  return (
    <Page
      title={step_title}
      currentStep={`${currentStep}_template_${templateCase}`}
      urls={props.urls}
      trackingPath={props.trackingPath}
      analytics={props.analytics}
      deviceType={props.deviceType}
    >
      <div className="layout layout--new-interface">
        {!discount?.is_applicable && (
          <GenericModal
            className="discount-modal"
            title={translations.GENERAL_CONDITIONS}
            content={discount?.detail}
            isOpen={showDiscountModal}
            // TODO - TECH DEBT: Unificate the deviceType & isMobile props, it's not consistent
            deviceType={props.deviceType}
            onClose={() => setShowDiscountModal(false)}
            onOpen={() => setShowDiscountModal(true)}
          />
        )}

        {/* Space for Header */}
        {!payer?.is_guest && flow.type !== CHECKOUT_TYPE.EMBED && (
          <Navbar
            user={{
              fullName: payer.name,
              firstname: payer.first_name,
              lastname: payer.last_name,
              email: payer.email.value,
              avatar: payer.avatar,
            }}
            menuOptions={NavbarMenuOptions}
            theme={theme}
            isMLB={siteId === SITE_ID.MLB}
            isMobile={isMobile(props.deviceType)}
            logOut={() => logout({ changeUser: false })}
            changeUser={() => logout({ changeUser: true })}
            altLogo={getBrandNameCompany(theme, translations)}
            altUser={payer.name}
            altClosedMenu={translations.YOU_ARE_USING_YOUR_ACCOUNT(payer.first_name)}
            altOpenedMenu={translations.CLOSE_ACCOUNT_OPTION}
          />
        )}

        <section className={`layout__col-content ${props.checkoutType === CHECKOUT_TYPE.MODAL && 'scroller'}`}>
          <div className="top-row">
            <Title component="h1" size="s">
              {step_title}
            </Title>
          </div>

          <div className="card-form-wrapper">
            <CardFormErrorBoundary
              cardFormActions={cardFormActions}
              stepActions={stepActions}
              flow={flow}
              history={history}
              internalBricksData={internalBricksData}
              meliSessionId={meliSessionId}
            >
              <InternalProviderBricks
                internalBricksData={internalBricksData}
                internalBricksIntegrationData={{
                  meliSessionId,
                }}
              >
                <CardFormProvider>
                  <CardFormWrapper
                    ref={cardFormWrapperRef}
                    publicKey={publicKey}
                    flow={flow}
                    stepActions={stepActions}
                    history={history}
                    step_model={step_model}
                    i18n={i18n}
                    rebrandingMP={rebrandingMP}
                  />
                </CardFormProvider>
              </InternalProviderBricks>
            </CardFormErrorBoundary>
          </div>
          <CallToActionButtons
            backButtonOptions={{
              show: can_return_to_options || (shouldReturnToOptions),
              label: translations.TO_GO_BACK,
              action: backButtonAction,
            }}
            continueButtonOptions={{
              label: translations.CONTINUE,
              action: handleTokenizeCardClick,
              disabled: isContinueBtnDisabled,
            }}
          />
        </section>

        <section className="layout__col-sidebar">
          {/* Sidebar */}
          {brand && <Brand image={brand.avatar} name={brand.name} />}

          {summary?.items?.length && (
            <PaymentSummary
              title={summary?.title}
              items={summary?.items?.map((item) =>
                handleSummaryItemProps({
                  item,
                  summary,
                  translations,
                  deviceType,
                  tooltipContainer,
                  setShowDiscountModal,
                  iconAlignment: PAYMENT_SUMMARY_ALIGNMENT.LEFT,
                }),
              )}
              total={
                summary?.items?.length > 1 && {
                  name: summary?.total?.name,
                  nameWeight: "regular",
                  price: summary?.total?.price.text_value,
                  priceWeight: "regular",
                  priceCents: summary?.total?.price.cents,
                  priceSize: 16,
                  textSize: "m",
                }
              }
              theme={THEME.DARK}
              companyTheme={getCompanyTheme(props.configs?.internalConfigurations)}
              showSubmitButton={false}
              isFullAndes
            />
          )}
        </section>
      </div>
    </Page>
  );
};

CardFormPage.propTypes = {
  flow: PropTypes.object.isRequired,
  currentStep: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  browserName: PropTypes.string,
  history: PropTypes.object,
  i18n: PropTypes.object,
  isManagingError: PropTypes.bool,
  stepActions: PropTypes.object,
  snackbarActions: PropTypes.object,
  cardFormActions: PropTypes.object,
  urls: PropTypes.object.isRequired,
  trackingPath: PropTypes.string.isRequired,
  analytics: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  configs: PropTypes.object.isRequired,
  templateCase: PropTypes.string,
  internalBricksData: PropTypes.object,
  step_model: PropTypes.shape({
    ...newInterfaceGenericPropsTypes,
    message: PropTypes.shape({
      text: PropTypes.string,
      status: PropTypes.string,
    }),
  }).isRequired,
};

CardFormPage.defaultProps = {
  ...genericDefaultValues,
  templateCase: 'default',
  internalBricksData: {},
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
  cardFormActions: bindActionCreators(CARD_FORM_ACTIONS, dispatch),
  snackbarActions: bindActionCreators(SNACKBAR_ACTIONS, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configs: state.configurations,
  browserName: state.configurations.browserName,
  isManagingError: state.cardFormBricks.isManagingError,
  platform: state.configurations.platform,
  rebrandingMP: state.configurations.rebrandingMP,
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = CardFormPage;
} else {
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(CardFormPage));
}
