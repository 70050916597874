// Warning: This is a class used by the server and by the client. DO NOT ADD SERVER LIBRARIES.
// If you need to pass values from configuration get them from the caller
const uuidv4 = require('uuid/v4');
const validator = require('validator');

const { DEVICE_TYPE } = require('../../constants/commons');
const logger = require('nordic/logger')('error');

// List of valid headers
const VALID_HEADERS = {
  USER_ID: {
    name: 'x-user-id',
  },
  X_API_TEST: {
    name: 'x-api-test',
  },
  X_REQUEST_ID: {
    name: 'x-request-id',
  },
  X_REQUEST_WITH: {
    name: 'X-Requested-With',
    value: {
      XMLHttpRequest: 'XMLHttpRequest',
    },
  },
  X_IP_ADDRESS: {
    name: 'x-ip-address',
  },
  X_USER_AGENT: {
    name: 'x-user-agent',
  },
  X_IDEMPOTENCY_KEY: {
    name: 'x-idempotency-key',
  },
  TRACKING_PRODUCT_ID: {
    name: 'x-product-id',
    value: {
      DESKTOP: {
        GUEST: {
          TOKENIZER: 'BC32A4JU643001OI3920',
          CHECKOUT: 'BC32A4RU643001OI3930',
        },
        REGISTERED: {
          TOKENIZER: 'BC32A4JU643001OI392G',
          CHECKOUT: 'BC32A7VTRPP001U8NHI0',
        },
      },
      MOBILE: {
        GUEST: {
          TOKENIZER: 'BC32A4NTRPP001U8NHGG',
          CHECKOUT: 'BC32A57TRPP001U8NHHG',
        },
        REGISTERED: {
          TOKENIZER: 'BC32A4NTRPP001U8NHH0',
          CHECKOUT: 'BC32A7RU643001OI393G',
          PX: 'CHFAJU7METM6JT9APTV0',
        },
      },
    },
  },
  X_D2ID: {
    name: 'x-d2id',
  },
  X_SSID: {
    name: 'x-ssid',
  },
  X_LOGGED_IN_STATUS: {
    name: 'x-logged-in',
  },
  X_CALLER_ID: {
    name: 'x-caller-id',
  },
  X_OPERATOR_ID: {
    name: 'x-operator-id',
  },
  X_KYC_INITIATIVE_ID: {
    name: 'x-kyc-initiative-id',
  },
  X_API_SANDBOX: {
    name: 'x-api-sandbox',
  },
  X_SOURCE: {
    name: 'x-source',
  },
  X_CHECKOUT_REFERER_URL: {
    name: 'x-checkout-referer-url',
  },
  OS_NAME: {
    name: 'os-name',
  },
  OS_VERSION: {
    name: 'os-version',
  },
  OS_NATIVE: {
    name: 'os-native',
  },
  X_USE_CASE: {
    name: 'x-use-case',
  },
  X_ORIGIN_REFERER: {
    name: 'x-origin-referer',
  },
  X_MELI_SESSION_ID: {
    name: 'x-meli-session-id',
  },
  X_VERIFY_REAUTH: {
    name: 'X-Verify-Reauth',
  },
  X_SITE_ID: {
    name: 'x-site-id',
  },
  X_SCOPE: {
    name: 'x-scope',
  },
  X_APPLICATION_ID: {
    name: 'x-application-id',
  },
  X_FLOW: {
    name: 'x-flow',
  },
  DETACHED_ID: {
    name: 'detached_id',
  },
  RTK: {
    name: 'rtk',
  },
  RTID: {
    name: 'rtid',
  },
};

class Headers {
  constructor() {
    this.headers = {};
  }

  /**
   * Add custom header
   * @param key
   * @param value
   * @returns {Headers}
   */
  add(key, value) {
    this.headers[key] = value;

    return this;
  }

  /**
   * Add user id to header
   * @param id
   * @returns {Headers}
   */
  addUserId(id) {
    this.add(VALID_HEADERS.USER_ID.name, id);

    return this;
  }

  /**
   * Add apiTest id to header
   * @param id
   * @returns {Headers}
   */
  addApiTest(apiTest) {
    this.add(VALID_HEADERS.X_API_TEST.name, apiTest);

    return this;
  }

  /**
   * Add X-Operator-Id if it's not empty
   * @param {number} id
   * @returns {Headers}
   */
  addOperatorId(id) {
    if (id) {
      this.add(VALID_HEADERS.X_OPERATOR_ID.name, id);
    }

    return this;
  }

  /**
   * Add X-Caller-Id if it's not empty
   * @param {number} id
   * @returns {Headers}
   */
  addCallerId(id) {
    if (id) {
      this.add(VALID_HEADERS.X_CALLER_ID.name, id);
    }

    return this;
  }

  /**
   * Add user ip to header
   * @param ip
   * @returns {Headers}
   */
  addIpAddress(ip) {
    this.add(VALID_HEADERS.X_IP_ADDRESS.name, ip);

    return this;
  }

  /**
   * Add user userAgent to header
   * @param userAgent
   * @returns {Headers}
   */
  addUserAgent(userAgent) {
    this.add(VALID_HEADERS.X_USER_AGENT.name, userAgent);

    return this;
  }

  /**
   * Add session id (d2id) to header
   * @param d2id
   * @returns {Headers}
   */
  addSessionId(d2id) {
    this.add(VALID_HEADERS.X_D2ID.name, d2id);

    return this;
  }

  /**
   * Add authentication id (ssid) to header
   * @param ssid
   * @returns {Headers}
   */
  addAuthenticationId(ssid) {
    this.add(VALID_HEADERS.X_SSID.name, ssid);

    return this;
  }

  /**
   * Add logged in status to header
   * @param {Boolean} isLoggedIn
   * @returns {Headers}
   */
  addLoggedInStatus(isLoggedIn) {
    this.add(VALID_HEADERS.X_LOGGED_IN_STATUS.name, isLoggedIn);

    return this;
  }

  /**
   * Add X-Request-Id
   * If you don't propagate and id generate one
   * @param id
   * @returns {Headers}
   */
  addXRequestId(id = '') {
    this.add(VALID_HEADERS.X_REQUEST_ID.name, id || uuidv4());

    return this;
  }

  /**
   * Add X-Idempotency-Key if it's not empty
   * @param key
   * @returns {Headers}
   */
  addIdempotencyKey(key) {
    if (key) {
      this.add(VALID_HEADERS.X_IDEMPOTENCY_KEY.name, key);
    }

    return this;
  }

  /**
   * Add product tracking id
   *
   * Reference: https://docs.google.com/spreadsheets/d/1ODf8YvX_qefX5llo3EtZkXVHkjgdK6tm5Yv7PLeZzj0n
   *
   * @param deviceType
   * @param isLogged
   * @returns {Headers}
   */
  addTrackingProductId(deviceType, isLogged, checkoutVersion) {
    // eslint-disable-next-line no-useless-catch
    try {
      const deviceKey = deviceType === DEVICE_TYPE.DESKTOP ? 'DESKTOP' : 'MOBILE';
      const userKey = isLogged ? 'REGISTERED' : 'GUEST';
      const version =  checkoutVersion.toUpperCase();
      const productId = VALID_HEADERS.TRACKING_PRODUCT_ID.value[deviceKey][userKey][version];

      this.add(VALID_HEADERS.TRACKING_PRODUCT_ID.name, productId);

      return this;
    } catch (error) {
     logger.error('Unable to set tracking product id for card form service');

      throw error;
    }
  }

  /**
   * Add source of request (button, link, etc)
   * @param source
   * @returns {Headers}
   */
  addSource(source) {
    if (source) {
      this.add(VALID_HEADERS.X_SOURCE.name, source);
    }

    return this;
  }

  /**
   * Add checkout url to flows request
   * @param {*} url
   * @returns {Headers}
   */
  addRefererUrl(url) {
    if (url && validator.isURL(url)) {
      this.add(VALID_HEADERS.X_CHECKOUT_REFERER_URL.name, url);
    }

    return this;
  }

  /**
   * Add device name to header
   * @param deviceType
   * @returns {Headers}
   */
  addDeviceName(deviceType) {
    this.add(VALID_HEADERS.OS_NAME.name, deviceType);

    return this;
  }

  /**
   * Add device version to header
   * @param deviceVersion
   * @returns {Headers}
   */
  addDeviceVersion(deviceVersion) {
    this.add(VALID_HEADERS.OS_VERSION.name, deviceVersion);

    return this;
  }

  /**
   * Add device native to header
   * @param isDeviceNative
   * @returns {Headers}
   */
  addDeviceNative(isDeviceNative) {
    this.add(VALID_HEADERS.OS_NATIVE.name, isDeviceNative);

    return this;
  }

  /**
   * Add use case to header
   * @param useCase
   * @returns {Headers}
   */
  addUseCase(useCase) {
    this.add(VALID_HEADERS.X_USE_CASE.name, useCase);

    return this;
  }

  /**
   * Add referer of request headers
   * @param referer
   * @returns {Headers}
   */
  addReferer(referer = null) {
    if (referer) {
      this.add(VALID_HEADERS.X_ORIGIN_REFERER.name, referer);
    }

    return this;
  }

  addReauthHeaders(reauthHeaders, detached_id) {
    reauthHeaders.rtk && this.add(`x-reauth-${VALID_HEADERS.RTK.name}`, reauthHeaders.rtk);
    detached_id && this.add(`x-reauth-${VALID_HEADERS.DETACHED_ID.name.replace('_', '-')}`, detached_id);
    reauthHeaders.rtid && this.add(`x-reauth-${VALID_HEADERS.RTID.name}`, reauthHeaders.rtid);
    return this;
  }

  /**
   * Add meli session id of request headers sent by mobile webkit
   * @param meliSessionId
   * @returns {Headers}
   */
  addMeliSessionId(meliSessionId = null) {
    if (meliSessionId) {
      this.add(VALID_HEADERS.X_MELI_SESSION_ID.name, meliSessionId);
    }

    return this;
  }

  /**
   * Get headers
   * @returns {{}}
   */
  get() {
    return this.headers;
  }

  /**
   * Get specific header by key
   * @param key
   * @returns {*|{}}
   */
  getByKey(key) {
    return this.headers[key];
  }

  /**
   * Remove key from headers
   * @param key
   * @returns {Headers}
   */
  remove(key) {
    delete this.headers[key];

    return this;
  }
}

module.exports = Headers;
module.exports.VALID_HEADERS = VALID_HEADERS;
