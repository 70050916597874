const { useCallback } = require('react');
const { Card } = require('@andes/card');
const { List, ListItem } = require('@andes/list');
const { RadioList, RadioListItem } = require('@andes/radio-list');
const { Typography } = require('@andes/typography');
const { useI18n } = require('nordic/i18n');

const { parseAmount } = require('../../../utils/currency');
const { getDeliveryDay, getDeliveryMonthTranslated } = require('../utils/delivery');

const translate = require('../../../translation');

const ShippingCost = ({ options, currency, onSelectOption, isDesktop, selectedShippingOptionId }) => {
  const { i18n } = useI18n();
  const translations = translate(i18n);

  const renderTitle = useCallback(
    ({ date }) => translations.ARRIVING_ON(getDeliveryDay(date), getDeliveryMonthTranslated(date, translations)),
    [],
  );

  const renderRightContent = useCallback(
    ({ free, cost }) =>
      free ? (
        <Typography size="s" color="positive">
          {translations.FREE_SHIPPING}
        </Typography>
      ) : (
        <Typography size="s" color="secondary">
          {parseAmount(cost, currency)}
        </Typography>
      ),
    [currency],
  );

  return (
    <>
      <Typography
        weight={isDesktop && 'semibold'}
        size={isDesktop ? 'l' : 'm'}
        color={isDesktop ? 'secondary' : 'primary'}
        className={isDesktop ? 'shipping-option-title' : ''}
      >
        {translations.SHIPPING}
      </Typography>

      {isDesktop ? (
        <Card>
          <RadioList
            value={selectedShippingOptionId}
            onChange={(_, value) => {
              onSelectOption(value);
            }}
          >
            {options.map(({ free, id, estimated_delivery_time: { date }, cost }) => (
              <RadioListItem
                className="shipping-option-radio"
                key={id}
                title={renderTitle({ date })}
                rightContent={renderRightContent({ free, cost })}
                value={id}
              />
            ))}
          </RadioList>
        </Card>
      ) : (
        options.map(({ free, id, estimated_delivery_time: { date }, cost }) => (
          <Card key={id}>
            <List withDividers srLabel="">
              <ListItem
                className="shipping-option"
                title={renderTitle({ date })}
                chevron
                rightContent={renderRightContent({ free, cost })}
                onClick={() => onSelectOption(id)}
              />
            </List>
          </Card>
        ))
      )}
    </>
  );
};

module.exports = {
  ShippingCost,
};
