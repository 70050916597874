/* eslint-disable import/order */
const React = require('react');

// Andes Components
const { Typography } = require('@andes/typography');
const { Card, CardContent } = require('@andes/card');

// Custom Components
const Info = require('../../../../components/icons/Info');
const IconEmail = require('../../../../components/icons/Mail');
const IconExtraData = require('../../../../components/icons/ExtraData');
const UserDefault = require('../../../../components/icons/UserDefault');

// Cow Components Icons
const InstallmentsIcon = require('@cow/core-components/components/Icons/InstallmentsIcon');

// Utils Nodes
const { isMobile } = require('../../../../utils/webview');
const { localeFromSiteId } = require('../../../../utils/locale');
const { isInterestFree, hasBankInterests } = require('../../../../utils/interests');
const { formatAmountWithInstallments } = require('../../../../utils/format');
const { getPaymentMethodIcon } = require('../../../../utils/icons');
const { getShippingOptions } = require('../utils/shipping');

// Constants
const { COLORS } = require('../../../../../constants');
const { PAYMENT_METHOD, PAYMENT_METHOD_TYPE } = require('../../../../../constants/commons');

// TODO: Add translations for other payment methods (CHECK WITH BACKEND)
const getTitleForUserExtraData = ({ translations }) => translations.DATA_FOR_YOUR_INVOCE;

const getBottomContentComponent = ({ paymentMethod, translations, isBppFlow }) => {
  if (paymentMethod.id === PAYMENT_METHOD.PIX && !isBppFlow) {
    return (
      <Card className="card-option__disclaimer">
        <CardContent>
          <Info width={15} height={12} />
          <Typography className="text" type="body" size="m">
            {translations.PIX_BPP}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return null;
};

const getPaymentMethodDescription = (paymentMethod) => {
  if (paymentMethod?.id === PAYMENT_METHOD.ACCOUNT_MONEY) {
    return paymentMethod?.tax?.subtitle || paymentMethod?.detail || '';
  }

  if (paymentMethod?.tax?.status === true) {
    return paymentMethod?.tax?.subtitle || '';
  }

  return paymentMethod.accreditation_description || paymentMethod.detail;
};

export const useCardList = ({
  payer,
  siteId,
  isGuest,
  shipping,
  isBppFlow,
  deviceType,
  translations,
  actions: {
    changePaymentMethod,
    changeInstallments,
    changeExtraData,
    changeShippingOption,
    changeShippingAddress,
    changeEmail,
  },
}) => {
  const buildCardList = (paymentMethods, isRebrandingMP = false) => {
    if (Array.isArray(paymentMethods) && (!paymentMethods || paymentMethods.length === 0)) {
      return [];
    }

    const options = [];
    const paymentMethod = paymentMethods[0];

    // Get Shipping Options
    shipping &&
      options.push(
        ...getShippingOptions({
          shipping,
          translations,
          changeShippingOption,
          changeShippingAddress,
          deviceType,
        }),
      );

    if (isGuest && payer.email?.value?.length > 0 && !payer.email?.is_required && paymentMethod?.id !== PAYMENT_METHOD.BOLETO) {
      options.push({
        id: 'email-row',
        title: payer.email?.value,
        icon: payer.email?.is_preference_email ? <UserDefault /> : <IconEmail />,
        color: COLORS.MP_ANDES_BLUE_500,
        onClick: payer.email?.is_preference_email ? null : changeEmail,
        callToAction: !payer.email?.is_preference_email && (
          <span className="card-option-cta">{translations.MODIFY}</span>
        ),
        className: `email-card-option email-card-option--${
          payer.email?.is_preference_email ? 'is-not-modifiable' : 'is-modifiable'
        }`,
        isMobile: isMobile(deviceType),
      });
    }

    const getIssuerName = () => {
      if (
        paymentMethod.id === PAYMENT_METHOD.PEC ||
        paymentMethod.id === PAYMENT_METHOD.BOLETO ||
        paymentMethod.id === PAYMENT_METHOD.SPEI ||
        paymentMethod.property_type !== PAYMENT_METHOD_TYPE.OFFLINE
      ) {
        return paymentMethod.id;
      }

      return paymentMethod.title;
    };

    const paymentMethodOption = {
      id: `${paymentMethod.id}-row`,
      title: paymentMethod.title,
      description: getPaymentMethodDescription(paymentMethod, deviceType),
      icon: getPaymentMethodIcon({
        siteId,
        type: paymentMethod.type,
        issuerName: getIssuerName(),
        issuerID: paymentMethod.issuer_id,
        escapeAccentMark: true,
        isRebrandingMP,
      }),
      onClick: changePaymentMethod,
      callToAction: <span className="card-option-cta">{translations.MODIFY}</span>,
      className: 'payment-method-option',
      bottomContent: getBottomContentComponent({ paymentMethod, translations, isBppFlow }),
      isMobile: isMobile(deviceType),
    };

    options.push(paymentMethodOption);

    if (
      paymentMethod.installment &&
      Object.keys(paymentMethod.installment)?.length > 0 &&
      paymentMethod.installment.details_required
    ) {
      const { locale, currency } = localeFromSiteId(siteId);

      const bankInterests = hasBankInterests(siteId);
      const interestFree = isInterestFree(paymentMethod, siteId) && !bankInterests;

      const description = formatAmountWithInstallments(
        siteId,
        locale,
        currency,
        paymentMethod.installment?.amount,
        paymentMethod.installment?.quantity,
        bankInterests,
      );

      const installmentsOption = {
        description,
        id: `installments-quantity-row`,
        title: translations.INSTALLMENTS_LABEL(),
        extraInfo: interestFree && translations.NO_INTEREST(),
        extraInfoColor: interestFree && 'green',
        isMobile: isMobile(deviceType),
        onClick: paymentMethod.installment?.is_modifiable && changeInstallments,
        callToAction: paymentMethod.installment?.is_modifiable && (
          <span className="card-option-cta">{translations.MODIFY}</span>
        ),
        icon: (
          <InstallmentsIcon
            fontWeight="bold"
            text={`${paymentMethod.installment?.quantity}x`}
            color={isRebrandingMP ? COLORS.MP_ANDES_YELLOWBLUE_500 : COLORS.MP_ANDES_BLUE_500}
          />
        ),
      };

      options.push(installmentsOption);
    }

    if (payer?.extra_data && Object.keys(payer.extra_data)?.length && payer.extra_data.payer_detail !== '') {
      const extraDataOption = {
        title: getTitleForUserExtraData({ paymentMethod, translations }),
        description: payer.extra_data.payer_detail,
        icon: <IconExtraData />,
        color: COLORS.MP_ANDES_BLUE_500,
        onClick: changeExtraData,
        callToAction: payer?.extra_data?.is_modifiable && (
          <span className="card-option-cta">{translations.MODIFY}</span>
        ),
        isMobile: isMobile(deviceType),
      };

      options.push(extraDataOption);
    }

    return options;
  };

  return {
    buildCardList,
  };
};
