const React = require('react');
const PropTypes = require('prop-types');

const injectI18n = require('nordic/i18n/injectI18n');
const Brand = require('@cow/core-components/components/Brand');
const { bindActionCreators} = require('redux');
const { connect } = require('react-redux');

const Desktop = require('./Desktop');
const Mobile = require('./Mobile');
const Navbar = require('../NavbarV2');
const {useNavbar} = require('../../hooks/useNavbar');
const {hasBrand} = require('../../utils/summary');
const Page = require('../Page');
const { COMMONS: { ENVIROMENT, DEVICE_TYPE}} = require('../../../constants/index');
const {isMobile} = require('../../utils/webview');
const SNACKBAR_ACTIONS = require('../../spa/actions/snackbar');
const STEP_ACTIONS = require('../../spa/actions/step');
const connectToReduxAndInjectI18n = require('../../utils/connectToRedux');

// TODO: migrate logic of the summary here
const LayoutContainer = (props) => {

  const {
    flow,
    siteId,
    mainContent,
    footerContent,
    sidebarContent,
    modalsContent,
    deviceType,
    stepActions,
    history,
    configs,
    browserName,
    brand,
    snackbarActions,
    stepTitle,
    payer,
    isPaymentSummaryCollapsible,
    i18n,
    templateCase,
    theme,
    translations,
    currentStep,
    trackingPath,
    analytics,
    urls,
    handleOpenNavbar,
    platform
  } = props;

  const {showNavbar} = useNavbar(flow, payer.is_guest, configs?.isWebview);

  let brandComponent = <></>;

  const navbarComponent = showNavbar ? (
    <Navbar
      browserName={browserName}
      deviceType={deviceType}
      flow={flow}
      history={history}
      payer={payer}
      siteId={siteId}
      stepActions={stepActions}
      snackbarActions={snackbarActions}
      theme={theme}
      translations={translations}
      onOpen={handleOpenNavbar}
      platform={platform}
      configurations={configs}
    />
  ) : <></>;

  if (deviceType === DEVICE_TYPE.MOBILE) {
    brandComponent = hasBrand(brand) && (
      <Brand
        image={brand.avatar}
        name={brand.name}
        isMobile={isMobile(props.deviceType)}
        isUserLogged={!payer.is_guest}
        withNavbar={showNavbar}
      />
    );
  }


  return (
    <Page
      i18n={i18n}
      title={stepTitle}
      currentStep={`${currentStep}_template_${templateCase}`}
      urls={urls}
      trackingPath={trackingPath}
      analytics={analytics}
      deviceType={deviceType}
    >
      <>
        {
          deviceType === DEVICE_TYPE.DESKTOP ?
            <Desktop
              mainContent={mainContent}
              sidebarContent={sidebarContent}
              footerContent={footerContent}
              navbarComponent={navbarComponent}
              modalsContent={modalsContent}
              stepTitle={stepTitle}
            /> :
            <Mobile
              modalsContent={modalsContent}
              navbarComponent={navbarComponent}
              mainContent={mainContent}
              sidebarContent={sidebarContent}
              stepTitle={stepTitle}
              isPaymentSummaryCollapsible={isPaymentSummaryCollapsible}
              brandComponent={brandComponent}
              footerContent={footerContent}
              payer={payer}
            />
        }
      </>
    </Page>
  );
};

LayoutContainer.propTypes = {
  flow: PropTypes.object,
  siteId: PropTypes.string,
  mainContent: PropTypes.node,
  footerContent: PropTypes.node,
  sidebarContent: PropTypes.node,
  modalsContent: PropTypes.node,
  deviceType: PropTypes.string,
  stepActions: PropTypes.array,
  history: PropTypes.object,
  configs: PropTypes.object.isRequired,
  browserName: PropTypes.string,
  brand: PropTypes.string,
  snackbarActions: PropTypes.array,
  stepTitle: PropTypes.string,
  payer: PropTypes.object,
  isPaymentSummaryCollapsible: PropTypes.bool,
  i18n: PropTypes.object,
  templateCase: PropTypes.string,
  theme: PropTypes.string,
  translations: PropTypes.object,
  currentStep: PropTypes.string,
  trackingPath: PropTypes.string,
  analytics: PropTypes.object,
  urls: PropTypes.object,
  handleOpenNavbar: PropTypes.func,
  platform: PropTypes.string,
};

LayoutContainer.defaultProps = {
  container: <></>,
  navbar: <></>,
  sidebar: <></>,
  modal: <></>,
  mainContent: <></>,
  i18n: {
    gettext: (t) => t
  }
};

const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configs: state.configurations,
  browserName:
  state.configurations.browserName,
  loadingStopAnimation: state.request.loadingStopAnimation,
  platform: state.configurations.platform,
});

const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(SNACKBAR_ACTIONS, dispatch),
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch)
});

module.exports = connectToReduxAndInjectI18n(LayoutContainer, mapStateToProps, mapDispatchToProps);
