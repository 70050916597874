const React = require('react');

// Custom Components
const SecurityCode = require('../components/SecurityCode');
const InstallmentsSelector = require('../components/InstallmentsSelector');
const { ICONS_METHODS } = require('../../../../../constants/commons');
const { Text } = require('@andes/typography');

const shouldRenderSecurityCode = (pm) => !!pm?.security_code;

const shouldRenderInstallments = (pm) => pm?.installments?.length !== 0 && !!pm?.show_installments;

const shouldRenderCollapsedContent = (pm, express) => {
  const cardToken = express?.[pm?.option_id]?.cardTokenId;
  const renderInstallments = shouldRenderInstallments(pm);

  if (cardToken) {
    return renderInstallments;
  }

  return shouldRenderSecurityCode(pm) || renderInstallments;
};

const CollapsedContent = ({ paymentMethod, discount }) => (
  <div className="review-express__collapsed-content">
    {shouldRenderSecurityCode(paymentMethod) && (
      <SecurityCode
        optionId={paymentMethod?.option_id}
        securityCode={paymentMethod?.security_code}
        escAvailable={paymentMethod?.esc_available}
        id={`${paymentMethod?.id}_${paymentMethod?.option_id}`}
      />
    )}

    {shouldRenderInstallments(paymentMethod) && (
      <InstallmentsSelector
        discount={discount}
        paymentMethod={paymentMethod?.option_id}
        items={paymentMethod?.installments}
      />
    )}
  </div>
);

const getPaymentMethodId = (paymentMethod, rebrandingMP) => {
  if (rebrandingMP) {
    if (paymentMethod?.id === ICONS_METHODS.ICON_ACCOUNT_MONEY_BLACK) {
      return ICONS_METHODS.ICON_ACCOUNT_MONEY_YELLOW;
    }

    if (paymentMethod?.id === ICONS_METHODS.ICON_CONSUMER_CREDITS) {
      return ICONS_METHODS.ICON_MERCADO_CREDITO_YELLOW;
    }
  }

  return paymentMethod?.id;
};

const CURRENCY_LABEL = 'Dinero'

const buildPaymentCardList = ({ paymentMethods, translations, discount, express, rebrandingMP }) =>
  paymentMethods.map((paymentMethod) => {
    const isMercadoPagoCard = paymentMethod?.title.includes(translations.MP) && !paymentMethod?.title.includes(CURRENCY_LABEL);
    const title = isMercadoPagoCard ? paymentMethod?.title.split('\n')[0] : paymentMethod?.title;
    const description = isMercadoPagoCard ? <Text size="m">{paymentMethod?.title.split('\n')[1]}</Text> : paymentMethod?.detail;

    const paymentMethodId = getPaymentMethodId(paymentMethod, rebrandingMP);

    const highlightBadge = paymentMethod?.recommended && {
      size: 'small',
      text: translations.RECOMMENDED_PAYMENT_METHOD,
    };

    const collapsedContent = shouldRenderCollapsedContent(paymentMethod, express) && (
      <CollapsedContent paymentMethod={paymentMethod} discount={discount} />
    );

    return {
      id: paymentMethod?.option_id,
      title,
      description,
      withPromotion: paymentMethod?.promotional,
      additionalInfo: paymentMethod?.additional_info,
      paymentMethodId,
      collapsedContent,
      highlightBadge,
    };
  });

const usePaymentCardList = ({ paymentMethods, translations, discount, express, rebrandingMP }) => {
  const [paymentCardList, setPaymentCardList] = React.useState(
    buildPaymentCardList({
      express,
      discount,
      translations,
      paymentMethods,
      rebrandingMP,
    }),
  );

  React.useEffect(() => {
    setPaymentCardList(
      buildPaymentCardList({
        express,
        discount,
        translations,
        paymentMethods,
        rebrandingMP,
      }),
    );
  }, [express, paymentMethods]);

  return {
    paymentCardList,
    shouldRenderInstallments,
    shouldRenderSecurityCode,
  };
};

module.exports = { usePaymentCardList };
