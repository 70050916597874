/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/order */
const React = require('react');
const { useState } = require('react');
const PropTypes = require('prop-types');

// Redux
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

// Andes Components
const { Title } = require('@andes/typography');
// Cow Components
const CardList = require('@cow/core-components/components/CardList');
const EmailInputCard = require('@cow/core-components/components/EmailInputCard');
// Custom Components
const ReviewBase = require('../index');

// Custom Hooks
const { useActions } = require('../../hooks/useActions');
const { useCardList } = require('../../hooks/useCardList');

// i18n
const injectI18n = require('nordic/i18n/injectI18n');
const translate = require('../../../../../translation');

// Constants
const {
  CHECKOUT_CASE: { GUEST },
} = require('../../../../../../constants/commons');
const { ENVIROMENT } = require('../../../../../../constants/commons');

// Utils
const PCJ = require('../../../../../utils/pcj');
const { EMAIL_INPUT_VALIDATIONS } = require('../../../../../utils/regex');
const {
  genericPropsTypes,
  genericDefaultValues,
  newInterfaceGenericPropsTypes,
  newInterfaceGenericDefaultValues,
} = require('../../../../../utils/propTypes');

// Actions
const STEP_ACTIONS = require('../../../../../spa/actions/step');
const REQUEST_ACTIONS = require('../../../../../spa/actions/request');
const captchaToken = require('../../../../../spa/actions/captchaToken');
const { INPUTS_ID } = require('../../../../../../constants/ui');

const ReviewGuest = (props) => {
  const translations = translate(props.i18n);

  const {
    siteId,
    flow,
    history,
    stepActions,
    requestActions,
    captchaToken,
    captchaConfig,
    rebrandingMP,
    step_model: { payer, payment_methods, captcha, shipping, errors, notification },
  } = props;

  const [emailState, setEmailState] = useState(payer.email?.value ?? '');
  const [emailErrorMessage, setEmailErrorMessage] = useState(errors?.payer?.email ?? '');

  // Actions Hook
  const {
    changePaymentMethod,
    changeExtraData,
    changeInstallments,
    changeShippingOption,
    changeShippingAddress,
    changeEmail,
  } = useActions({
    flow,
    history,
    stepActions,
    payer,
    captcha,
    requestActions,
    captchaToken,
    captchaConfig,
  });

  // Card List Hook
  const { buildCardList } = useCardList({
    siteId,
    translations,
    payer,
    emailState,
    shipping,
    isGuest: true,
    actions: {
      changePaymentMethod,
      changeInstallments,
      changeExtraData,
      changeShippingOption,
      changeShippingAddress,
      changeEmail,
    },
  });

  const cardListOptions = buildCardList(payment_methods, rebrandingMP);

  const handleEmailInput = (event) => {
    setEmailState(event.target.value);
    setEmailErrorMessage('');
  };

  const handleEmailInputError = (data) => {
    setEmailErrorMessage(data.message);
  };

  // Update emails message with flow response
  React.useEffect(() => {
    setEmailErrorMessage(errors?.payer?.email ?? '');
  }, [errors?.payer?.email]);

  // Reset states
  React.useEffect(() => {
    if (notification) {
      setEmailState('');
    }
  }, [notification]);

  return (
    <ReviewBase
      templateCase={GUEST}
      email={emailState}
      setEmail={setEmailState}
      setEmailErrorMessage={setEmailErrorMessage}
      emailErrorMessage={emailErrorMessage}
      customCardList={<CardList items={cardListOptions} />}
      bellowIncentives={
        payer?.email?.is_required && (
          <div className="guest-email">
            <Title size="xs" className="title" component="div">
              {translations.RECEIVE_PAYMENT_DETAILS}
            </Title>
            <EmailInputCard
              id={INPUTS_ID.USER_EMAIL}
              label={translations.EMAIL}
              helper={emailErrorMessage || translations.SEND_ONLY_PAYMENT_INFO_FOR_AVAILABILITY}
              value={emailState}
              onChange={handleEmailInput}
              onError={handleEmailInputError}
              style={emailErrorMessage ? 'error' : undefined}
              validations={EMAIL_INPUT_VALIDATIONS(translations)}
              icon={null}
            />
          </div>
        )
      }
      {...PCJ.ifCandidateInjectPropsIntoSummary({ props, paymentMethod: payment_methods?.[0], translations })}
    />
  );
};

ReviewGuest.defaultProps = {
  step_model: {
    ...newInterfaceGenericDefaultValues,
  },
  ...genericDefaultValues,
};

ReviewGuest.propTypes = {
  templateCase: PropTypes.string,
  step_model: PropTypes.shape({
    ...newInterfaceGenericPropsTypes,
  }).isRequired,
  ...genericPropsTypes,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
  captchaToken: bindActionCreators(captchaToken, dispatch),
  requestActions: bindActionCreators(REQUEST_ACTIONS, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  flow: state.page.flow,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  captchaConfig: state.configurations.captcha,
  installment: state.installment,
  currency: state.configurations.currency,
  rebrandingMP: state.configurations.rebrandingMP,
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = ReviewGuest;
} else {
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(ReviewGuest));
}
