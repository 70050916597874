const { Card } = require('@andes/card');
const { List, ListItem } = require('@andes/list');
const { Thumbnail } = require('@andes/thumbnail');
const { Button } = require('@andes/button');
const { useI18n } = require('nordic/i18n');

const { generateIcon } = require('../../../utils/icons');
const translate = require('../../../translation');

const Address = ({ zipcode_text, street_text, contact_text, onSelectOption, isRebrand }) => {
  const { i18n } = useI18n();
  const translations = translate(i18n);

  return (
    <Card className="address-card">
      <List withDividers srLabel={zipcode_text} className="list-mobile">
        <ListItem
          className="address-item"
          srLabel={zipcode_text}
          title={street_text}
          description={[<div key={zipcode_text}>{zipcode_text}</div>, <div key={contact_text}>{contact_text}</div>]}
          leftContent={
            <Thumbnail className="shipping-icon-container" size="40" srLabel={zipcode_text}>
              {generateIcon({
                type: 'icon_pin',
                className: isRebrand ? 'shipping-rebrand-color' : 'shipping-icon-address',
              })}
            </Thumbnail>
          }
          rightContent={
            <Button hierarchy="transparent" onClick={() => onSelectOption('address_row')}>
              {translations.MODIFY}
            </Button>
          }
        />
      </List>
    </Card>
  );
};

module.exports = {
  Address,
};
