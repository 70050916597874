const React = require('react');

const IconWrapper = require('../IconWrapper');

const LocalPick = ({ className, isRebrand } = {}) => (
  <IconWrapper>
    <svg
      className={className ? className : 'with-custom-color'}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 36 36"
    >
      <g fill="none" fillRule="evenodd" transform="translate(7 3)">
        <path
          fill={isRebrand ? 'rgba(0, 0, 0, 0.9)' : '#99D8F4'}
          d="M14 18a4.002 4.002 0 013.858 2.94c-1.738 2.607-4.024 5.544-6.858 8.808-2.833-3.264-5.12-6.2-6.858-8.807A4 4 0 018 18h6z"
        />
        <circle cx="11" cy="11" r="5" fill={isRebrand ? 'rgba(0, 0, 0, 0.9)' : '#99D8F4'} />
        <path
          stroke="currentColor"
          strokeWidth="1.1"
          d="M11 29.748C18.333 21.3 22 15.05 22 11c0-6.075-4.925-11-11-11S0 4.925 0 11c0 4.05 3.667 10.3 11 18.748z"
        />
      </g>
    </svg>
  </IconWrapper>
);

module.exports = React.memo(LocalPick);
