/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

// Store
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

// Andes Components
const { Message } = require('@andes/message');
const { Title, Text } = require('@andes/typography');

// Cow Components
const Navbar = require('@cow/core-components/components/NavbarV2');
const PaymentSummary = require('@cow/core-components/components/PaymentSummary');
const CardList = require('@cow/core-components/components/CardList');
const CouponCollapsible = require('@cow/core-components/components/CouponCollapsible');
const CardMercadoCredits = require('@cow/core-components/components/MercadoCreditsBanner');
const KycModal = require('@cow/core-components/components/KycModalV2');
const GenericModal = require('@cow/core-components/components/GenericModal');
const CardSwitch = require('@cow/core-components/components/CardSwitch');
const Brand = require('@cow/core-components/components/Brand');
const Term = require('@cow/core-components/components/Term');
const Footer = require('@cow/core-components/components/Footer');
const ButtonBackURLs = require('../../../../components/ButtonBackURLs');

// Custom Components
const CaptchaV2 = require('../../../../components/CaptchaV2');

// Template Utils
const { handleSummaryItemProps, PAYMENT_SUMMARY_ALIGNMENT } = require('../../../../utils/summary');

// Custom Hooks
const { useActions } = require('../hooks/useActions');
const { useCardList } = require('../hooks/useCardList');
const { useNavbar } = require('../../../../hooks/useNavbar');
const { useLogOut } = require('../../../../hooks/useLogOut');

// i18n
const injectI18n = require('nordic/i18n/injectI18n');
const translate = require('../../../../translation');

// Internal Components
const Page = require('../../../../components/Page');
const DynamicContent = require('../../../../components/DynamicContent');
const DiscountTrigger = require('../components/DiscountTrigger');

// Constants
const {
  SELECTORS,
  UI: { INPUTS_ID, BUTTONS },
  APP: { CAPTCHA_VERSION, SUMMARY_ITEMS_TYPES, USER_TYPES },
  COMMONS: {
    THEME,
    ASSETS,
    SITE_ID,
    ENVIROMENT,
    EXTERNAL_URLS,
    PAYMENT_METHOD,
    COLORS_BY_STATUS,
    CHECKOUT_CASE: { SUBSCRIPTION },
    CHECKOUT_TYPE: { MODAL },
  },
} = require('../../../../../constants');

// Actions
const STEP_ACTIONS = require('../../../../spa/actions/step');
const SNACKBAR_ACTIONS = require('../../../../spa/actions/snackbar');
const ANIMATION_ACTIONS = require('../../../../spa/actions/animations');
const LOADING_ACTIONS = require('../../../../spa/actions/loading');
const REQUEST_ACTIONS = require('../../../../spa/actions/request');
const CAPTCHA_TOKEN_ACTIONS = require('../../../../spa/actions/captchaToken');

// Utils

const { sanitizeUrl } = require('../../../../utils/UrlUtils');
const { getMessageColor } = require('../utils/subscription');
const { hasBankInterests } = require('../../../../utils/interests');
const { getMercadoPagoIconBySiteId } = require('../../../../utils/icons');
const { createToggleFunctionWithAnimation } = require('../utils/discount');
const {
  genericPropsTypes,
  genericDefaultValues,
  newInterfaceGenericPropsTypes,
  newInterfaceGenericDefaultValues,
} = require('../../../../utils/propTypes');
const { getCompanyTheme, getBrandNameCompany } = require('../utils/configurations');
const { isMobile } = require('../../../../utils/webview');
const { hasCaptcha } = require('../../../../utils/terms-and-conditions');
const PCJ = require('../../../../utils/pcj');

const Review = (originalProps) => {
  const translations = translate(originalProps.i18n);

  const props = {
    ...PCJ.ifCandidateInjectPropsIntoSummary({
      translations,
      props: originalProps,
      paymentMethod: originalProps.step_model.payment_methods[0],
    }),
  };

  const {
    flow,
    history,
    stepActions,
    snackbarActions,
    bellowIncentives,
    blockedByCaptcha,
    customCardList,
    customSidebar,
    email,
    emailErrorMessage,
    setEmailErrorMessage,
    browserName,
    siteId,
    requestActions,
    loadingActions,
    loadingPayButton,
    requestLoading,
    loadingStopAnimation,
    captchaConfig,
    captchaToken,
    checkoutType,
    rebrandingMP,
    showMlaTaxes = true,
    showKycModal = true,
    showIncentives = true,
    customSummaryItems = null,
    consumerCreditsFooter = null,
    consumerCreditsSummary = null,
    customHandlePayClick = null,
    customFooterMessage = null,
    customHandleKyc = null,
    customPrice = null,
    step_model: {
      transaction_type,
      challenge_instructions,
      payer,
      step_title,
      discount,
      summary,
      main_action,
      payment_methods,
      incentives,
      brand,
      terms_and_conditions,
      subscription,
      alternative_payment_method,
      captcha,
      notification,
      shipping,
      errors,
      navigation,
    },
    platform,
    configs,
  } = props;

  const NavbarMenuOptions = {
    title: translations.YOU_ARE_LOGGED_AS,
    changeUserLabel: translations.CHANGE_ACCOUNT,
    logOutUserLabel: translations.LOG_OUT,
  };

  const templateCase = props.templateCase || 'default';

  // Ref
  const tooltipContainer = React.createRef();

  // States
  const [discountCodeState, setDiscountCodeState] = React.useState('');
  const [discountCodeErrorMessage, setDiscountCodeErrorMessage] = React.useState(errors?.discount?.code ?? '');
  const [showDiscountModal, setShowDiscountModal] = React.useState(false);
  const [isCouponVisible, setIsCouponVisible] = React.useState(false);
  const [useAlternativePaymentMethod, setUseAlternativePaymentMethod] = React.useState(
    alternative_payment_method?.enabled,
  );

  const progressDone = loadingStopAnimation && requestLoading;
  const SummaryTheme = THEME.LIGHT;
  const hasDiscountCodeApplied = summary.items.find((item) => item.type === SUMMARY_ITEMS_TYPES.COUPON_DISCOUNT);
  const summaryItems = customSummaryItems || summary?.items;

  // Actions Hook
  const {
    showNotification,
    changePaymentMethod,
    changeInstallments,
    dismissConsumerCredits,
    payConsumerCredits,
    sentDiscountCode,
    changeExtraData,
    kyc,
    pay,
    changeShippingOption,
    changeShippingAddress,
  } = useActions({
    siteId,
    browserName,
    flow,
    history,
    stepActions,
    snackbarActions,
    setDiscountCodeErrorMessage,
    payer,
    email,
    emailErrorMessage,
    setEmailErrorMessage,
    captcha,
    requestActions,
    captchaToken,
    captchaConfig,
    translations,
    useAlternativePaymentMethod,
    loadingActions,
    platform,
    configurations: configs,
    discountCode: discountCodeState,
    challengesInstructions: challenge_instructions,
  });

  const {
    logout
  } = useLogOut({
    flow,
    siteId,
    browserName,
    history,
    stepActions,
    snackbarActions,
    translations,
    platform,
    configurations: configs
  });

  // Card List Hook
  const { buildCardList } = useCardList({
    payer,
    siteId,
    shipping,
    translations,
    isGuest: payer.is_guest,
    isBppFlow: incentives?.is_bpp_flow,
    actions: {
      changePaymentMethod,
      changeInstallments,
      changeExtraData,
      changeShippingOption,
      changeShippingAddress,
    },
  });

  // Build Card List Options
  const cardListOptions = buildCardList(payment_methods, rebrandingMP);

  const getElement = () => document.querySelector('.content-row');
  const getVisibleState = () => isCouponVisible;
  const setVisibleState = (state) => setIsCouponVisible(state);

  const toggleDiscount = createToggleFunctionWithAnimation(getElement, getVisibleState, setVisibleState);

  const toggleAlternativePaymentMethod = () => {
    setUseAlternativePaymentMethod((prevState) => !prevState);
  };

  const handleDiscountCodeInput = (event) => {
    setDiscountCodeState(event.target.value);
    setDiscountCodeErrorMessage('');
  };

  // Handle Pay Click (submit button)
  const handlePayClick = () => {
    if (customHandlePayClick) {
      customHandlePayClick();
      return;
    }

    pay();
  };

  // Notifications
  React.useEffect(() => {
    if (notification) {
      // TODO IMPROVE THIS BEHAVIOR TO MATCH WITH PRODUCTION
      document.getElementById(BUTTONS.DISCOUNT_TRIGGER)?.click();
      setDiscountCodeState('');

      showNotification({ status: COLORS_BY_STATUS[notification.status?.toUpperCase()], component: notification.message, displayTimeout: 10000 });
    }
  }, [notification]);

  // Discount Animation
  React.useEffect(() => {
    if (discount?.is_applicable) {
      const contentContainer = document.querySelector('.content');
      const contentRowContainer = document.querySelector('.content-row');

      contentContainer.style.position = 'relative';
      contentRowContainer.classList.add('content-row--animated');

      // Set the correct size for the "content" element when the "content-row" be major
      // This is because the "content-row" is using position: absolute and the "content" element is using position: relative (For the animation purpose)
      if (contentRowContainer.offsetHeight > contentContainer.offsetHeight) {
        contentContainer.style.height = `${contentRowContainer.offsetHeight}px`;
      }
    }
  }, []);

  const theme = getCompanyTheme(props.configs?.internalConfigurations);
  const { showNavbar } = useNavbar(flow, payer.is_guest, props.configs?.isWebview);
  const paymentMethod = payment_methods?.[0];

  const contentClassName = classNames('content', {
    'scroller': checkoutType === MODAL,
    'content--guest': payer.is_guest,
    [SELECTORS.REBRANDING]: rebrandingMP,
  });

  return (
    <Page
      title={props.stepTitle}
      currentStep={`${props.currentStep}_template_${templateCase}`}
      urls={props.urls}
      trackingPath={props.trackingPath}
      analytics={props.analytics}
      deviceType={props.deviceType}
    >
      <div className="layout layout--new-interface">
        {/* Space for Modals */}
        {!!challenge_instructions?.kyc && showKycModal && (
          <KycModal
            dispatcherSelector={`.${BUTTONS.DESKTOP_PAY}`}
            description={rebrandingMP ? translations.KYC_DESCRIPTION : challenge_instructions.kyc.description}
            // TODO - TECH DEBT: Unificate the deviceType & isMobile props, it's not consistent
            deviceType={props.deviceType}
            title={rebrandingMP ? translations.KYC_TITLE : challenge_instructions.kyc.title}
            image={rebrandingMP ? ASSETS.KYC_IMAGE_REBRANDING_DESKTOP : ASSETS.KYC_IMAGE}
            submitLabel={rebrandingMP ? translations.KYC_BTN_VALIDATION : translations.START}
            alternativeOptionLabel={rebrandingMP ? translations.KYC_BTN_PAY_WITH_ANOTHER : translations.PAY_WITH_ANOTHER}
            onSubmit={customHandleKyc || kyc}
            onSubmitAlternativeOption={changePaymentMethod}
            className={`kyc-modal--${challenge_instructions.kyc.type}`}
          />
        )}

        {discount?.is_applicable && (
          <GenericModal
            className="discount-modal"
            title={translations.GENERAL_CONDITIONS}
            content={<>{discount?.detail}</>}
            isOpen={showDiscountModal}
            // TODO - TECH DEBT: Unificate the deviceType & isMobile props, it's not consistent
            deviceType={props.deviceType}
            onClose={() => setShowDiscountModal(false)}
            onOpen={() => setShowDiscountModal(true)}
          />
        )}

        {/* Space for Header */}
        {showNavbar && (
          <Navbar
            user={{
              fullName: payer.name,
              firstname: payer.first_name,
              lastname: payer.last_name,
              email: payer.email.value,
              avatar: payer.avatar,
            }}
            menuOptions={NavbarMenuOptions}
            theme={theme}
            isMLB={siteId === SITE_ID.MLB}
            isMobile={isMobile(props.deviceType)}
            logOut={() => logout({ changeUser: false })}
            changeUser={() => logout({ changeUser: true })}
            altLogo={getBrandNameCompany(theme, translations)}
            altUser={payer.name}
            altClosedMenu={translations.YOU_ARE_USING_YOUR_ACCOUNT(payer.first_name)}
            altOpenedMenu={translations.CLOSE_ACCOUNT_OPTION}
          />
        )}

        <section className="layout__col-content">
          {/* Space for Step title & Coupon */}
          <div className="top-row">
            <Title component="h3" size="s">
              {step_title}
            </Title>
            <DiscountTrigger
              isApplicable={discount?.is_applicable}
              labelToggle={translations.I_HAVE_A_DISCOUNT}
              discountTriggerId={BUTTONS.DISCOUNT_TRIGGER}
              toggleDiscount={toggleDiscount}
            />
          </div>

          <div className={contentClassName}>
            {/* Space for Notifications */}
            {subscription?.free_trial && Object.keys(subscription?.free_trial)?.length && (
              <Message
                className="message__free-trial"
                color={getMessageColor(subscription)}
                hierarchy="quiet"
                title={subscription.free_trial.title}
              >
                {subscription.free_trial.label}
              </Message>
            )}

            {paymentMethod.id === PAYMENT_METHOD.SPEI && (
              <Message id="card-option__spei" hierarchy="quiet">
                {translations.CLABE_MESSAGE}
              </Message>
            )}

            <div className="content-row">

              <DynamicContent defaultContent={<CardList items={cardListOptions} />} customContent={customCardList} />

              {siteId === SITE_ID.MLA && showMlaTaxes && paymentMethod?.installment && (
                <div className="card-taxes-mla">
                  <Text size="xs" color="secondary" component="p" className="card-taxes-mla__cost">
                    {paymentMethod.installment?.total_finance_cost}
                  </Text>

                  {paymentMethod.installment?.detail && (
                    <Text size="xs" color="secondary" component="p" className="card-taxes-mla__total">
                      {paymentMethod.installment?.detail}
                    </Text>
                  )}
                </div>
              )}

              {hasBankInterests(siteId) && paymentMethod?.installment?.quantity > 1 && (
                <Message hierarchy="quiet" color="accent" className="bank_interests">
                  {translations.BANK_INTERESTS}
                </Message>
              )}

              <DynamicContent
                defaultContent={
                  <>
                    {incentives?.credits && showIncentives && (
                      <CardMercadoCredits
                        title={translations.PURCHASE_NOW_AND_PAY_LATER}
                        description={incentives?.credits?.description || ''}
                        promotion={incentives?.credits?.interest_text || ''}
                        submitLabel={translations.PAY_WITH_MERCADO_CREDIT}
                        onClose={dismissConsumerCredits}
                        onClick={payConsumerCredits}
                        isRebrandingMP={rebrandingMP}
                      />
                    )}

                    {subscription?.validation && Object.keys(subscription?.validation)?.length && (
                      <Message
                        className="message__payment-validation"
                        color={COLORS_BY_STATUS[subscription.validation.status]}
                        hierarchy="quiet"
                        title={subscription.validation.title}
                      >
                        {subscription.validation.label}
                      </Message>
                    )}

                    {/* Combination Card Switch */}
                    {/* TODO: Use i18n for the text of the 'description' prop of the CardSwitch component (Check with the UX team if the wording exists) */}
                    {useAlternativePaymentMethod !== null && useAlternativePaymentMethod !== undefined && (
                      <CardSwitch
                        description="Usar mi dinero disponible en Mercado Pago cuando no sea posible cobrar de la tarjeta"
                        icon={getMercadoPagoIconBySiteId(siteId, SITE_ID.MLA === siteId, rebrandingMP)}
                        onChange={toggleAlternativePaymentMethod}
                        value={useAlternativePaymentMethod}
                      />
                    )}
                  </>
                }
                customContent={bellowIncentives} // FIXME -> Challengemos aqui el nombre de la prop o si amerita un nuevo DynamicContent
              />

              {/* Footer messages */}
              <div className="footer-terms">
                {customFooterMessage}

                {consumerCreditsFooter}
              </div>

              {/* Back link */}
              {navigation?.back_urls?.failure?.length > 0 && (
                <ButtonBackURLs label={translations.RETURN_TO_SITE} url={sanitizeUrl(navigation.back_urls.failure)} />
              )}
            </div>

            <CouponCollapsible
              value={discountCodeState}
              inputId={INPUTS_ID.DISCOUNT_CODE}
              labelInput={translations.DISCOUNT_CODE}
              description={discountCodeErrorMessage}
              submitButtonLabel={translations.APPLY_DISCOUNT}
              style={discountCodeErrorMessage ? 'error' : undefined}
              onClick={sentDiscountCode}
              onChange={handleDiscountCodeInput}
              show={discount?.is_applicable}
            />
          </div>
        </section>

        {/* Summary */}
        <section className="layout__col-sidebar">
          {brand && <Brand image={brand.avatar} name={brand.name} />}

          <DynamicContent
            defaultContent={
              <div className="sidebar--default">
                <PaymentSummary
                  title={summary?.title}
                  items={summaryItems?.map((item) =>
                    handleSummaryItemProps({
                      item,
                      summary,
                      translations,
                      deviceType: props.deviceType,
                      tooltipContainer,
                      setShowDiscountModal,
                      iconAlignment: PAYMENT_SUMMARY_ALIGNMENT.LEFT,
                      isSubscription: transaction_type === SUBSCRIPTION,
                    }),
                  )}
                  total={{
                    name: summary?.total?.name,
                    nameWeight: summary?.total?.nameWeight || 'semibold',
                    nameObservation: subscription?.next_invoice ?? '',
                    nameObservationColor: 'secondary',
                    nameObservationSize: 'xs',
                    helperText: subscription?.description ?? '',
                    priceSize: 16,
                    priceCents: summary?.total?.price.cents,
                    priceObservation: summary?.total?.detail,
                    priceObservationColor: summary?.total?.detailColor || 'positive',
                    observationDirection: summary?.total?.detailDirection || 'column',
                    priceWeight: summary?.total?.priceWeight || 'semibold',
                    price: customPrice || summary?.total?.price.text_value,
                  }}
                  buttonText={main_action}
                  onPayClick={handlePayClick}
                  securityPaymentInformationLabel={incentives?.is_secure_payment ? translations.SECURE_PAYMENT : null}
                  theme={SummaryTheme}
                  companyTheme={getCompanyTheme(props.configs?.internalConfigurations)}
                  bottomContent={
                    <>
                      {consumerCreditsSummary}

                      {payer.is_guest && terms_and_conditions?.has_legals && (
                        <Term
                          acceptLabel={translations.ACCEPT_THE_TERMS_V2}
                          termAndConditionsLabel={translations.TERMS_AND_CONDITIONS}
                          termsAndConditionsLink={props.urls?.tyc?.link}
                          brandLabel={translations.OF_MP}
                        />
                      )}

                      {captcha?.version === CAPTCHA_VERSION.V2 && (
                        <div
                          className={`payment-summary__bottom-content cow-captcha__container cow-captcha__container--${payer.is_guest ? USER_TYPES.GUEST : USER_TYPES.LOGGED
                            }`}
                        >
                          <CaptchaV2
                            {...props}
                            show={captcha?.version === CAPTCHA_VERSION.V2}
                            captchaRetry={captcha?.is_retry || false}
                          />
                        </div>
                      )}
                    </>
                  }
                  disablePayButton={blockedByCaptcha || (isCouponVisible && !hasDiscountCodeApplied)}
                  payButtonHelperText={subscription?.instructions}
                  loadingButton={loadingPayButton && !progressDone}
                />
              </div>
            }
            customContent={customSidebar}
          />
        </section>
        { // INFO: The Footer component escapes when it is MODAL because, in modal, the footer is incorporated into the content section.
          props.checkoutType !== MODAL &&
          <section className="layout__col-footer">
            <Footer
              requiredMessage={translations.PROCESSED_BY_MERCADO_PAGO}
              requiredScreenReaderMessage={translations.SCREEN_READER_FOOTER_MESSAGE}
              deviceType={props.deviceType}
              optionalInformation={hasCaptcha(terms_and_conditions?.has_captcha, translations)}
            />
          </section>
        }
      </div>
    </Page >
  );
};

Review.defaultProps = {
  step_model: {
    ...newInterfaceGenericDefaultValues,
  },
  ...genericDefaultValues,
  // States
  email: '',
  browserName: '',
  showKycModal: true,
  showMlaTaxes: true,
  showIncentives: true,
  requestLoading: false,
  loadingPayButton: false,
  blockedByCaptcha: false,
  loadingStopAnimation: false,
  // Elements
  customPrice: undefined,
  customSidebar: undefined,
  customCardList: undefined,
  bellowIncentives: undefined,
  customNotifications: undefined,
  customFooterMessage: undefined,
  consumerCreditsFooter: undefined,
  consumerCreditsSummary: undefined,
  customSummaryItems: undefined,
  // Actions
  customHandlePayClick: undefined,
  customHandleKyc: undefined,
};

Review.propTypes = {
  templateCase: PropTypes.string,
  step_model: PropTypes.shape({
    ...newInterfaceGenericPropsTypes,
  }).isRequired,
  ...genericPropsTypes,
  // States
  email: PropTypes.string,
  browserName: PropTypes.string,
  requestLoading: PropTypes.bool,
  blockedByCaptcha: PropTypes.bool,
  loadingPayButton: PropTypes.bool,
  loadingStopAnimation: PropTypes.bool,
  setEmailErrorMessage: PropTypes.func,
  emailErrorMessage: PropTypes.string,
  showIncentives: PropTypes.bool,
  showKycModal: PropTypes.bool,
  showMlaTaxes: PropTypes.bool,
  // Elements
  customPrice: PropTypes.node,
  customSidebar: PropTypes.node,
  customCardList: PropTypes.node,
  bellowIncentives: PropTypes.node,
  customNotifications: PropTypes.node,
  customFooterMessage: PropTypes.node,
  consumerCreditsFooter: PropTypes.node,
  consumerCreditsSummary: PropTypes.node,
  customSummaryItems: PropTypes.arrayOf(PropTypes.shape({})),
  // Actions
  customHandlePayClick: PropTypes.func,
  customHandleKyc: PropTypes.func,
};

/**
 * Map all the actions with the dispatchers on the props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(SNACKBAR_ACTIONS, dispatch),
  stepActions: bindActionCreators(STEP_ACTIONS, dispatch),
  animationActions: bindActionCreators(ANIMATION_ACTIONS, dispatch),
  captchaToken: bindActionCreators(CAPTCHA_TOKEN_ACTIONS, dispatch),
  requestActions: bindActionCreators(REQUEST_ACTIONS, dispatch),
  loadingActions: bindActionCreators(LOADING_ACTIONS, dispatch),
});

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  flow: state.page.flow,
  configs: state.configurations,
  firstRender: state.page.firstRender,
  basePath: state.configurations.basePath,
  captchaConfig: state.configurations.captcha,
  browserName: state.configurations.browserName,
  requestLoading: state.request.loading,
  loadingPayButton: state.request.loadingPayButton,
  loadingStopAnimation: state.request.loadingStopAnimation,
  blockedByCaptcha: state.captchaToken.blockedByCaptcha,
  checkoutType: state.configurations.checkoutType,
  currency: state.configurations.currency,
  platform: state.configurations.platform,
  rebrandingMP: state.configurations.rebrandingMP,
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = Review;
} else {
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(Review));
}
