const React = require('react');
const PropTypes = require('prop-types');

const {Title, Typography} = require('@andes/typography');
const classnames = require('classnames');

const {ANDES_STYLES_THEME} = require('../../../constants');
const {hasBrand} = require('../../utils/summary');


const Mobile = (props) => {

  const {
    mainContent,
    footerContent,
    sidebarContent,
    modalsContent,
    navbarComponent,
    brandComponent,
    brand,
    isPaymentSummaryCollapsible,
    stepTitle,
    payer,
  } = props;


  return (
    <>
      <div className="layout layout--new-interface">
        {modalsContent}
        {navbarComponent}

        {brandComponent}

        {
          sidebarContent &&
          <section className="layout__col-sidebar">
            {sidebarContent}
          </section>
        }

        <section className={
          classnames(
            'layout__main-content',
            payer.is_guest ? 'user-guest' : 'user-logged',
            {
              'cow-brand-content': hasBrand(brand),
              'is-payment-summary-static': isPaymentSummaryCollapsible,
            },
          )}
        >
          <Title className="layout__title" component="h1" size={ANDES_STYLES_THEME.SIZES.S}>
            {stepTitle}
          </Title>
          {mainContent}
        </section>
      </div>
      {footerContent}
    </>
  );
};

Mobile.propTypes = {
  mainContent: PropTypes.node,
  footerContent: PropTypes.node,
  sidebarContent: PropTypes.node,
  modalsContent: PropTypes.node,
  navbarComponent: PropTypes.node,
  brandComponent: PropTypes.node,
  brand: PropTypes.bool,
  isPaymentSummaryCollapsible: PropTypes.bool,
  stepTitle: PropTypes.string,
  payer: PropTypes.object,
};


module.exports = Mobile;
