const React = require('react');
const PropTypes = require('prop-types');


const { Title, Typography } = require('@andes/typography');
const { ANDES_STYLES_THEME } = require('../../../constants');

const Desktop = (props) => {

  const {
    mainContent,
    sidebarContent,
    modalsContent,
    footerContent,
    navbarComponent,
    stepTitle,
  } = props;


  return (
    <div className="layout layout--new-interface">
      {modalsContent}
      {navbarComponent}
      <section className="layout__col-content">
        <Title className="layout__title" component="h1" size={ANDES_STYLES_THEME.SIZES.S}>
          {stepTitle}
        </Title>
        {mainContent}
        {footerContent}
      </section>
      <section className="layout__col-sidebar">
        {sidebarContent}
      </section>
    </div>
  );
};

Desktop.propTypes = {
  mainContent: PropTypes.node,
  sidebarContent: PropTypes.node,
  modalsContent: PropTypes.node,
  navbarComponent: PropTypes.node,
  footerContent: PropTypes.node,
  stepTitle: PropTypes.string,
};

module.exports = Desktop;
