const {
  QUERY_PARAMS,
  NATIVE_APP_NAME_MAP,
  NATIVE_WEBKIT_APP_NAME_MAP,
  PAY_IN_APP: { ACTIVITY },
  PARAM_OBJECT_NAME: { INTERNAL_CONFIGURATIONS },
  PAY_IN_APP,
  DEVICE_OS_NAME,
} = require('../../constants/app');
const { VALID_HEADERS } = require('./Headers');

const castAppNameToDeeplinkName = (nativeAppName) => {
  if (nativeAppName === NATIVE_WEBKIT_APP_NAME_MAP.MercadoLibre) {
    return NATIVE_APP_NAME_MAP.MercadoLibre;
  }
  if (nativeAppName === NATIVE_WEBKIT_APP_NAME_MAP.MercadoPago) {
    return NATIVE_APP_NAME_MAP.MercadoPago;
  }
  return '';
};

/**
 * Get application name
 * @returns {string}
 */
const getDeeplinkAppName = (forceAppName) => {
  if (forceAppName === NATIVE_APP_NAME_MAP.MercadoLibre || forceAppName === NATIVE_APP_NAME_MAP.MercadoPago) {
    return forceAppName;
  }
  const castedAppName = castAppNameToDeeplinkName(forceAppName);
  if(castedAppName){
    return castedAppName;
  }
  return NATIVE_APP_NAME_MAP.MercadoPago;
};

/**
 * Check if it's an internal url (mp or ml ... other domains may apply)
 * @TODO Complete with all posible internal urls
 * @param {*} url
 * @returns {bool}
 */
const isInternalUrl = (url) => {
  const _url = new URL(url);
  const domain = _url.hostname;

  const regex =
    /(www|beta).(mercadopago.com|mercadopago.cl|mercadoshops.com|mercadolivre.com|mercadolibre.com|mercadolibre.cl)(.(ar|br|mx|co|ve|uy|pe))?/g;

  return !!domain.match(regex);
};

/**
 * Checks if it's an internal deeplink
 * @param {*} url
 * @returns {bool}
 */
const isInternalDeepLink = (url) => !!(url && url.toString().match(/meli:\/\/|mercadopago:\/\//g));

const deeplinkQueryParams = (customParams = {}) => {
  const queryParams = {
    use_web_title: false,
    back_style: 'arrow',
    loading_mode: 'none',
    ...customParams,
  };

  return new URLSearchParams(queryParams).toString();
};

const buildSniffingPxParams = (req, px_flow_id, is_payment_link_flow, public_key, checkoutSessionId) => {
  const { query } = req;

  const params = {
    pref_id: query[QUERY_PARAMS.PREFERENCE_ID],
    flow_id: `${px_flow_id || query?.px_tracking}`,
    session_id: query[QUERY_PARAMS.ROUTER_REQUEST_ID],
    coupon_enabled: true,
  };

  if (checkoutSessionId && public_key && !is_payment_link_flow) {
    params.checkout_session_id = checkoutSessionId;
    params.public_key = public_key;
  }

  if (!is_payment_link_flow) {
    params.product_id = VALID_HEADERS.TRACKING_PRODUCT_ID.value.MOBILE.REGISTERED.PX;
  }

  return params;
};

const buildSniffingWebviewParams = (optionalParams = {}) => {
  const {authRequired} = optionalParams;
  const params = {
    title: 'Checkout',
    'webkit-engine': 2,
  };
  if(authRequired){
    params.authentication_mode =  'required';
    params.loading_mode = 'spinner';
  }
  return params;
};

/**
 *
 * @param {*} url
 * @param {*} customActivity
 * @param {*} customParams
 * @returns {string}
 */
const createInternalDeeplink = (activity, customParams = {}, forceAppName) => {
  const deeplinkparams = deeplinkQueryParams(customParams);
  return `${getDeeplinkAppName(forceAppName)}://${activity}/?${deeplinkparams}`;
};

const createInternalWebviewDeeplink = (url, customParams = {}, forceAppName) => {
  if (url && !customParams.url) {
    customParams.url = url;
  }
  return createInternalDeeplink(ACTIVITY.WEBVIEW, customParams, forceAppName);
};

/**
 * Create Sniffing PX deeplink
 * @param {*} req
 * @param {*} res
 * @returns {string}
 */
const createSniffingPXDeeplink = (req, forceAppName, apiResponse, checkoutSessionId) => {
  const { px_flow_id, is_payment_link_flow, public_key } = apiResponse;
  const params = buildSniffingPxParams(req, px_flow_id, is_payment_link_flow, public_key, checkoutSessionId);

  return createInternalDeeplink(ACTIVITY.PAY_PREFERENCE, params, forceAppName);
};

const validMobileOS = (deviceName) =>
  [DEVICE_OS_NAME.ANDROID, DEVICE_OS_NAME.IOS].includes(deviceName);

const getVersionsByOS = (req, forceAppName, featureVersions) => {
  const deviceName = req.device?.osName;

  if (featureVersions && featureVersions.length > 0 && validMobileOS(deviceName)) {
    if (NATIVE_APP_NAME_MAP.MercadoPago === forceAppName) {
      return featureVersions
        .filter(({ mp_version }) => mp_version[deviceName] !== null)
        .map(({ feature_name, mp_version }) => ({ feature_name, version: mp_version[deviceName] }));
    }
    if (NATIVE_APP_NAME_MAP.MercadoLibre === forceAppName) {
      return featureVersions
        .filter(({ ml_version }) => ml_version[deviceName] !== null)
        .map(({ feature_name, ml_version }) => ({ feature_name, version: ml_version[deviceName] }));
    }
  }

  return null;
}

/**
 * Create Sniffing Webview deeplink
 * @param {*} req
 * @param {*} forceAppName
 * @param {*} checkoutURL
 * @param {*} apiResponse
 * @param {String} checkoutSessionId
 * @returns {string}
 */
const createSniffingWebviewDeeplink = (req, forceAppName, checkoutURL, apiResponse, checkoutSessionId) => {
  const params = buildSniffingWebviewParams();
  const urlParams = new URLSearchParams({
    [INTERNAL_CONFIGURATIONS]: PAY_IN_APP.APP_STRATEGY.WEBVIEW,
    [PAY_IN_APP.PARAMS.SNIFFED]: true,
    [PAY_IN_APP.PARAMS.APP]: forceAppName,
  });

  if (checkoutSessionId) {
    urlParams.append('checkout_session_id', checkoutSessionId);
  }

  if(apiResponse){ // APPEND HEADERS ONLY FOR APP VERSION
    const {px_flow_id, is_payment_link_flow, app_strategy, feature_versions, public_key} = apiResponse;
    urlParams.append('px_flow_id', px_flow_id);
    urlParams.append('is_payment_link_flow', is_payment_link_flow);

    if(feature_versions && feature_versions.length > 0){
      // We keep only the data we need based on the app and OS we're building the deeplink for
      const filtered_versions = getVersionsByOS(req, forceAppName, feature_versions);

      if(filtered_versions && filtered_versions.length > 0) {
        urlParams.append('feature_versions', JSON.stringify(filtered_versions));
      }
    }

    urlParams.append('strategy', app_strategy);
    if(apiResponse.product_id) {
      urlParams.append('product_id', apiResponse.product_id);
    }
    if(public_key) {
      urlParams.append('public_key', apiResponse.public_key);
    }
  }
  const builtURL = `${checkoutURL}&${urlParams}`;
  return createInternalWebviewDeeplink(builtURL, params, forceAppName);
};

/**
 *
 * @param {*} customActivity
 * @param {*} customParams
 * @returns {string}
 */
const createAppDeepLinkFromClient = (customActivity, customParams = null) => {
  const userAgent = (window?.navigator?.userAgent || '').toLocaleLowerCase();
  const isMeliApp = !!userAgent.match(/mercadolibre/g);
  const appName = isMeliApp ? NATIVE_APP_NAME_MAP.MercadoLibre : NATIVE_APP_NAME_MAP.MercadoPago;
  return createInternalDeeplink(customActivity, customParams, appName);
}

const isExternalDeepLinkFromBackUrl = (url) => {
  const internal = ['mercadolibre://', 'mercadopago://', 'meli://'];
  const pattern = /^(https?):\/\/[\w.-]+:\/\/[\w\/.-]+/;
  return url.match(pattern) && !internal.some((str) => url.search(str) !== -1);
};

module.exports = {
  isInternalDeepLink,
  isInternalUrl,
  createInternalWebviewDeeplink,
  createAppDeepLinkFromClient,
  createSniffingPXDeeplink,
  createSniffingWebviewDeeplink,
  getDeeplinkAppName,
  castAppNameToDeeplinkName,
  getVersionsByOS,
  isExternalDeepLinkFromBackUrl,
  buildSniffingWebviewParams,
};
